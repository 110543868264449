(($) => {

  const galleries = [document.querySelector('.gallery-impact-stories'),document.querySelector('.gallery-testimonials')];

  galleries.forEach(gallery => {

    const type = gallery.dataset.type;
    const full_posts = document.querySelector(`.gallery__full-posts[data-type*=${type}]`);
    const screen =     full_posts.querySelector('.gallery__screen');
    const nav_jump = document.querySelector('.nav-jump');

    screen.addEventListener("click",() => {
      if (active) setActivePost(active,false);
    })
    nav_jump.addEventListener("click",() => {
      if (active) setActivePost(active,false);
    })

    let active = undefined;
    const posts = {};
    // const incrementActivePost = (amt) => {
    //   let pNew = active + amt;
    //   if (pNew < 0) {
    //     pNew = posts.length + pNew;
    //   } else if (pNew >= posts.length) {
    //     pNew = 0 + (pNew - posts.length);
    //   }
    //   setActivePost(pNew,true);
    // }
    const setActivePost = (id,on) => {
      if (posts[active]) posts[active].full.classList.remove("active");
      active = on ? id : null;
      if (on) {
        posts[id].full.classList.add("active");
        screen.classList.add("active");
        document.documentElement.classList.add("gallery-card-active");
      } else {
        posts[id].full.classList.remove("active");
        screen.classList.remove("active");
        document.documentElement.classList.remove("gallery-card-active");
      }
      
    }

    document.addEventListener("keydown",(e) => {
      e = e || window.event;
      var isEscape = false;
      if ("key" in e) {
          isEscape = (e.key === "Escape" || e.key === "Esc");
      } else {
          isEscape = (e.keyCode === 27);
      }
      if (isEscape && posts[active]) {
        setActivePost(active,false);
      }
    })
    

    const setCardHandlers = () => {

      const items = gallery.querySelectorAll('.gallery__item');

      Array.from(items).forEach((preview,p) => {
        const id = preview.dataset.post;
        const full = full_posts.querySelector(`.post__full[data-post="${id}"]`);
        posts[id] = {
          id:id,
          preview:preview,
          full:full
        };
        preview.addEventListener("click",() => {
          setActivePost(id,true);
        })
        full.querySelector(".card__close").addEventListener("click",() => {
          setActivePost(id,false);
        })
      })
    }

    setCardHandlers();

    const controls = Array.from(full_posts.querySelectorAll(".card__nav"));
    controls.forEach(control => {
      const prev = control.querySelector(".nav-prev a");
      const prevId = prev.dataset.rel;
      prev.addEventListener("click",(e) => {
        e.preventDefault();
        setActivePost(prevId,true);
      })
      
      const next = control.querySelector(".nav-next a");
      const nextId = next.dataset.rel;
      next.addEventListener("click",(e) => {
         e.preventDefault();
        setActivePost(nextId,true);
      })
    })

    const galleryControls = gallery.querySelector(".gallery__controls");

    const updateVisualPositions = function() {
      const cur = this.pos.cur;
      const max = (this.pos.end - this.pos.start) / 2;
      this.items.forEach(item => {
        const dist = Math.abs(cur - item.center);
        item.el.style.transform = `translateY(${dist / 3 - Math.sqrt(dist)}px)`;
      })
    }

    const appendItems = (s,prepend) => {
      const newItems = items.clone();
      const lang = document.documentElement.lang;
      const textPrimary = lang == "es-ES" ? "EXPLORE MÁS" : "Explore Further";
      const textSecondary = lang == "es-ES" ? "Haga clic en cada tarjeta para obtener más información" : "Click on each card to learn more";
      const spacer = $("<div class='scroll-prompt'><small>"+textPrimary+"</small><strong>"+textSecondary+"</strong></div>");
      const active = s.rel.activeItem;
      if (prepend) {
        $(slidee).prepend(spacer); 
        $(slidee).prepend(newItems); 
        s.reload();
        s.activate(active + newItems.length + spacer.length,true);
      } else {
        $(slidee).append(spacer); 
        $(slidee).append(newItems); 
        s.reload();
      }
      setCardHandlers();
    }

    const frame = gallery.querySelector(".gallery__inner");
    const slidee = gallery.querySelector(".gallery__items");
    const items = $(slidee).children();
    const sly = new Sly(frame, {
      next:galleryControls.querySelector(".control-next"),
      prev:galleryControls.querySelector(".control-prev"),
      slidee: slidee,
      horizontal: true,
      itemNav: 'forceCentered',
      smart: 1,
      mouseDragging: 1,
      touchDragging: 1,
      releaseSwing: 1,
      activateMiddle: true,
      speed: 2000,
      dragHandle: 1,
      dynamicHandle: 1,
      clickBar: 1,
      scrollbar:null,
      scrollBy: 1,
      startAt: window.innerWidth < 800 ? items.length + 1 : items.length
    }, null)
    
    appendItems(sly);

    sly.on('load move', updateVisualPositions);

    sly.on('change', function () {
      if (this.pos.dest > this.pos.end - (window.innerWidth/2)) {
        appendItems(sly);
      } else if (this.pos.dest < this.pos.start + (window.innerWidth/2)) {
        appendItems(sly,true);
      }
    });

    sly.init();
    window.addEventListener("resize",() => {
      sly.reload();
    });

    if (location.hash && location.hash.length > 1) {
      const hash = location.hash.substring(1,location.hash.length);
      const anchorPost = gallery.querySelector(`[data-anchor='${hash}']`);
      if (anchorPost) {
        const anchorPostId = anchorPost.dataset.post;
        if (posts[anchorPostId]) {
          history.pushState({},"","#discover");
          window.addEventListener("appInitialized",() => {
            setActivePost(anchorPostId,true);
          });
        }
      }
    }

  })


})(jQuery)